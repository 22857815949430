import { 
  FC, 
  useEffect, 
  useState 
} from 'react';
import Button from '../../ui/buttons/button';
import Text from '../../ui/text';
import { 
  TDeliveryDate,
  TDeliveryPoint,
  TDeliveryType, 
  TFormOrder 
} from '../../services/types/data';
import Input from '../../ui/inputs/input';
import Form from '../../ui/form/form';
import cn from 'classnames';
import Select from '../../ui/inputs/select';
import { Loader } from '../../ui/loader/loader';
import Textarea from '../../ui/inputs/textarea';
import styles from './form-order.module.css';
import { useLocation } from 'react-router-dom';
import { useSelector } from '../../services/hooks';

interface IFormSeedlingProps {
  value: TFormOrder;
  handleClickSubmit: (e: React.FormEvent) => void;
  handleChange: (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>
  ) => void;
  pointsFilter: ReadonlyArray<TDeliveryPoint> | null;
  handleCancel: () => void;
  request?: boolean;
  isValid: boolean;
  edit?: boolean;
}

const FormOrder: FC<IFormSeedlingProps> = ({
  value,
  handleClickSubmit,
  handleChange,
  pointsFilter,
  handleCancel,
  request,
  isValid,
  edit,
}) => {
  const { 
    deliveryTypes,
    deliveryChooseDates,
    order,
  } = useSelector(state => state.main);
  const { pathname } = useLocation();
  const textButton = pathname === '/cart' ? 'Оформить' : 'Изменить';
  const deliveryTypesShow: Array<TDeliveryType> | null = deliveryTypes?.filter(type => type.show) || null;
  const deliveryPoint: TDeliveryPoint | undefined = pointsFilter?.find(point => point.point === value['delivery-point']);

  const [deliveryDates, setDeliveryDates] = useState<readonly TDeliveryDate[] | null>(null);

  useEffect(() => {
    setDeliveryDates(deliveryChooseDates)
    if (order?.delivery.date && deliveryPoint?.point === order.delivery.point.point && deliveryChooseDates) {
      if (!deliveryChooseDates.some(date => date.date === order.delivery.date.date)) {
        setDeliveryDates(deliveryChooseDates.concat(order?.delivery.date));
      } 
    }
  }, [deliveryPoint, deliveryChooseDates, order]);

  return (
    <Form
      name='order'
      legend={edit ? 'Редактирование заказа' : 'Оформление заказа'}
      onSubmit={handleClickSubmit}
      className={styles.form}
      classFieldset={styles.fieldset}
    >
      <Input
        name='email'
        placeholder='E-mail'
        value={value.email}
        onChange={handleChange}
        extraClass={styles.label}
        label='Почта'
      />
      <Input
        name='first-name'
        placeholder='Имя'
        value={value['first-name']}
        maxLength={50}
        onChange={handleChange}
        extraClass={cn(styles.error, styles.label)}
        label={!value['first-name'] ? '*Обязательное для заполнения поле' : 'Имя'}
        required={true}
      />
      <Input
        onChange={handleChange}
        placeholder='Фамилия'
        value={value['last-name']}
        name='last-name'
        maxLength={50}
        required={true}
        label={!value['last-name'] ? '*Обязательное для заполнения поле' : 'Фамилия'}
        extraClass={cn(styles.error, styles.label)}
      />
      <Input
        placeholder='Телефон'
        onChange={handleChange}
        value={value['phone-number']}
        name='phone-number'
        required={true}
        label={
          !value['phone-number'] || value['phone-number'] === '+7' 
          ? '*Обязательное для заполнения поле' 
          : 'Телефон'
        }
        extraClass={cn(styles.error, styles.label)}
      />
      <Select
        name='delivery-type'
        valuesDeliveryTypes={deliveryTypesShow}
        onChange={handleChange}
        value={value['delivery-type']}
        label='Выбрать тип доставки'
        className={styles.select}
      />
      <Text 
        className={styles.comment}
        text={deliveryTypesShow?.find(el => el.type === value['delivery-type'])?.description} 
      />
      {pointsFilter?.length ? (
        <Select
          name='delivery-point'
          valuesDeliveryPoints={pointsFilter}
          onChange={handleChange}
          value={value['delivery-point']}
          label='Выбрать точку доставки'
          className={styles.select}
        />
      ) : (
        <Text 
          className={cn(styles.select, styles.comment, styles.error)}
          text='* Нет доступных точек для выбранного типа доставки'
        />
      )}
      <Text
        className={styles.comment}
        text={pointsFilter?.find(el => el.point === value['delivery-point'])?.location} 
      />
      {deliveryDates?.length ? (
        <Select
          name='delivery-date'
          valuesDeliveryDates={deliveryDates}
          onChange={handleChange}
          value={value['delivery-date'] || ''}
          label='Выбрать дату доставки'
          className={styles.select}
        />
      ) : (deliveryPoint?.['dates-required'] && (
        <Text
          className={cn(styles.select, styles.comment, styles.error)}
          text='* Нет доступных дат для выбранной точки доставки'
        />
      ))}
      <Textarea
        name='delivery-comment'
        placeholder='Комментарий'
        handleChange={handleChange}
        value={value['delivery-comment'] || ''}
        maxLength={450}
      />
      <div className={styles.box}>
        <Button
          className={cn(styles.button, styles.order)}
          type='submit'
          disabled={!isValid || !pointsFilter?.length}
        >
          {request ? <Loader size='small' /> : textButton}
        </Button>
        <Button
          className={cn(styles.button, styles.order)}
          clickHandler={handleCancel}
          disabled={request}
        >
          <Text text='Отменить' />
        </Button>
      </div>
    </Form>
  )
};

export default FormOrder;