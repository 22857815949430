import { Middleware, MiddlewareAPI } from 'redux';
import { AppDispatch, RootState } from '../types/store';
import { 
  connectionClosed, 
  connectionError, 
  connectionSuccess, 
  getMessage 
} from '../../store/ws-slice';


export const socketMiddleware = (
  wsUrl: string,
): Middleware => {
  return (store: MiddlewareAPI<AppDispatch, RootState>) => {
    let socket: WebSocket | null = null;

    return next => action => {
      const { dispatch, getState } = store;
      const { type } = action;
      const { token } = getState().user;

      if (type === 'ws/connectionStart') {
        socket = new WebSocket(wsUrl);
      }

      if (socket) {
        socket.onopen = event => {
          token && socket?.send(token);
          dispatch(connectionSuccess());
        }

        socket.onmessage = event => {
          const { data } = event;
          if (data === 'ping') {
            socket?.send('pong');
          } else {
            const parsedData = JSON.parse(data);
            const { msg, cnt } = parsedData;
            const { countNotification } = getState().ws;
            if (msg === 'The connection is established' && cnt) dispatch(getMessage(cnt))
            if (msg === 'Notification has been viewed' && !cnt) dispatch(getMessage(countNotification - 1))
            if (msg === 'New notification' && !cnt) dispatch(getMessage(countNotification + 1))
          }
        };

        socket.onerror = event => {
          dispatch(connectionError(event));
        };

        socket.onclose = event => {
          dispatch(connectionClosed());
        };

        if (type === 'ws/connectionFinish') {
          socket.close(1000, 'Закрытие cоединения')
        }
      }

      next(action);
    };
  };
};